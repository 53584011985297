Object.defineProperty(exports, "__esModule", {
    value: true
  });
  
const loadChatWidget = () => {
    window.HFCHAT_CONFIG = {
        EMBED_TOKEN: 'a30cfdc0-8791-11ef-b38f-293e48c91ded',
        ASSETS_URL: 'https://widget.happyfoxchat.com/v2/visitor',
          onload: function() {
            var HappyFoxChat = this;
            HappyFoxChat.setVisitorInfo({
              name: localStorage.getItem('username'),
              email:  localStorage.getItem('emailState'),
              phoneNumber: localStorage.getItem('phoneNumber'),
            }, function(err, resp) {
              if (err) {
                console.error('Failed to set visitor details. Error:', err);
              } else {
                console.log('Added visitor details:', resp);
              }
            });
          }
    };
  
    (function () {
        var scriptTag = document.createElement('script');
        scriptTag.type = 'text/javascript';
        scriptTag.async = true;
        scriptTag.src = window.HFCHAT_CONFIG.ASSETS_URL + '/js/widget-loader.js';
  
        var s = document.getElementsByTagName('script')[0];
        if (s && s.parentNode) {
            s.parentNode.insertBefore(scriptTag, s);
        } else {
            document.head.appendChild(scriptTag);
        }
    })();
  }

  const userNameChange = () => {
    const staticString = "hfc-visitor";
    let visitorData = null;
    let visitorKey = null;

    for (let i = 0; i < localStorage.length; i++) {
      const key = localStorage.key(i);
      if (key.startsWith(staticString)) {
        visitorData = JSON.parse(localStorage.getItem(key));
        visitorKey = key;
        break;
      }
    }
    const newName = localStorage.getItem('username')
    const newMobileNumber = localStorage.getItem('phoneNumber')
    visitorData.name = newName
    visitorData.phoneNumber = newMobileNumber

    localStorage.setItem(visitorKey, JSON.stringify(visitorData))
  }

  exports.loadChatWidget = loadChatWidget;
  exports.userNameChange = userNameChange;