


//@ts-nocheck
import React from "react";

import {

    // Customizable Area Start
    Container,
    Box,
    Button,
    Typography,
    Grid,
    MenuItem,
    IconButton,
    styled,
    TextField,
    InputLabel,
    InputAdornment,
    Modal,

    // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start

import CircularProgress from '@material-ui/core/CircularProgress';
import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';
import { createTheme, ThemeProvider, withStyles } from "@material-ui/core/styles";
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import HorizontalLine from "../../../components/src/HorizontalLine.web"
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import Visibility from "@material-ui/icons/Visibility";
import Autocomplete from '@material-ui/lab/Autocomplete';
import Sidebarmenu from "../../../components/src/Sidebarmenu.web";
import AppWithoutDrawer from "../../../components/src/AppWithoutDrawer.web";
import CloseOutlinedIcon from '@material-ui/icons/CloseOutlined';

export interface IDItem {
    label: string,
    value: string,
    sortName?: string | ''
  }

const theme = createTheme({
    palette: {
        primary: {
            main: "#nnn",
            contrastText: "#fff",
        },
    },
    typography: {
        h6: {
            fontWeight: 500,
        },
        subtitle1: {
            margin: "20px 0px",
        },
    },
    overrides: {
        MuiIconButton: {
            root: {
                padding: 0
            },
        },
    },
});

const StyledTextField = withStyles({
    root: {
        maxWidth: '100%',
        fontFamily: "Montserrat",
        fontSize: '16',
        color: '#44444f',
        "& .MuiFilledInput-root": {
            backgroundColor: "transparent",
            fontFamily: "Montserrat",
        },
        "& .MuiFilledInput-root:hover": {
            backgroundColor: "transparent",
            "@media (hover: none)": {
                backgroundColor: "transparent",
            }
        },
        "& .MuiFilledInput-root.Mui-focused": {
            backgroundColor: "transparent",
        },
        "& input::placeholder": {
            fontFamily: "Montserrat",
            fontSize: '16',
            fontWeight: 400,
            color: '#44444f'

        },
        "& .MuiInputBase-input.MuiInput-input": {
            fontFamily: "Montserrat",
            whiteSpace: 'normal'
        }
    }
})((props: any) => <TextField {...props} />);
// Customizable Area End

import CustomisableUserProfilesController, {
    Props,

} from "./CustomisableUserProfilesController";

class AccountSettings extends CustomisableUserProfilesController {
    popoverAnchor: Element | ((element: Element) => Element) | null | undefined;
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End 
    }

    // Customizable Area Start

    conditionalRenderFunc = () => {
                    
            return (
                <>          
                    {(this.state.residenceCountries ==="Philippines") && this.state.residenceCities != "" ?
                        <>
                            <Grid item xs={12} md={7} style={{ marginTop: '1.5em'}}>
                                <InputLabel style={webStyle.inputLabelStyle}>Barangay *</InputLabel>
                                <StyledTextField
                                    id="barangayBirth"
                                    select
                                    fullWidth
                                    name="barangayBirth"
                                    data-test-id='birth'
                                    InputLabelProps={{ shrink: false }}
                                    value={this.state.residenceBarangay1 }
                                    onChange={this.onBarangaySelected}
                                >
                                    <MenuItem style={{color: 'darkgrey'}} disabled value="choose"> <span style={{ color: "darkgrey" }}> Please Select </span></MenuItem>
                                    {this.state.barangayListOne.map((item: IDItem) => {
                                            return (
                                                <MenuItem
                                                    data-test-id="menuItemId"
                                                    key={item?.id}
                                                    value={item.value}
                                                >
                                                    {item?.label}
                                                </MenuItem>
                                            )

                                        })

                                    }
                                </StyledTextField>

                        </Grid>

                        <Grid item xs={12} md={7} >

                            <Box>
                                {
                                    (this.state.errors.residenceBarangay1)
                                        ? <small style={webStyle.errorStyle}>{this.state.errors.residenceBarangay1}</small>
                                        : <small style={{ visibility: 'hidden' }}>""</small>
                                }
                            </Box>

                        </Grid>

                    </>

                    : null}


                <Grid item xs={12} md={7} style={{ marginTop: '1.2em' }}>
                    <InputLabel style={webStyle.inputLabelStyle}>Reason for Considering Homeschooling *</InputLabel>
                    <StyledTextField
                        testidentity="Reason for Considering Homeschooling"
                        select
                        InputLabelProps={{ shrink: false }}
                        fullWidth={true}
                        name='homeSchoolingReasonOne'
                        value={this.state.homeSchoolingReasonOne}
                        defaultValue={this.state.homeSchoolingReasonOne}
                        placeholder="Please Select"
                        onChange={this.onHomeSchoolingReasonSelected}
                    >
                        <MenuItem style={{ color: '#ABABB0' }} disabled value="choose"> <span style={{ color: "#ABABB0" }} value={this.state.homeSchoolingReasonOne}>Please Select</span></MenuItem>
                        {
                            this.state.reasonListOne?.map((item: any, index: number) => (
                                <MenuItem
                                    style={{ wordWrap: 'break-word', whiteSpace: 'normal', overflow: 'hidden', textOverflow: 'ellipsis' }}

                                    value={item.value}
                                >
                                    {item?.label}
                                </MenuItem>
                            ))
                        }

                    </StyledTextField>
                    <Box>
                        {
                            (this.state.errors.homeSchoolingReasonOne)
                                ? <small style={webStyle.errorStyle}>{this.state.errors.homeSchoolingReasonOne}</small>
                                : <small style={{ visibility: 'hidden' }}>""</small>
                        }
                    </Box>
                </Grid>




            </>
        )
    }

    againComplexcityresolve = () => {
        return (
            <>
                <Grid item xs={12} md={7} style={{ marginTop: '1.5em' }}>
                    <InputLabel style={webStyle.inputLabelStyle}>Email Address *</InputLabel>
                    <StyledTextField
                        testidentity="email_address"
                        placeholder="email@mail.com"
                        fullWidth
                        type="email"
                        name='AccountEmail'
                        value={this.state.AccountEmail}
                        disabled
                        onChange={(e: any) => this.setState({ AccountEmail: e.target.value })}
                        onFocus={this.hideError}
                    />
                    <Box>
                        {
                            (this.state.errors.AccountEmail)
                                ? <small style={webStyle.errorStyle}>{this.state.errors.AccountEmail}</small>
                                : <small style={{ visibility: 'hidden' }}>""</small>
                        }
                    </Box>
                </Grid>
                <Grid item xs={12} md={7} style={{ marginTop: '1.5em' }}>
                    <InputLabel style={webStyle.inputLabelStyle}>Country of Residence *</InputLabel>
                    <Autocomplete
                        testidentity="Country_residense"
                        onChange={(e: any, newValue: any) => {
                            this.onResidenceCountrySelectedOne(newValue);
                        }}
                        options={this.state.countryListOne}
                        getOptionLabel={this.handleOptionLabelSelectedthree}
                        renderInput={(params: any) =>
                            <StyledTextField {...params} placeholder='Select Country' fullWidth={true}
                                testidentity="PleaseSelect"
                                name='country' InputLabelProps={{ shrink: false }}
                            />}
                        value={this.state.countryListOne.find(item => item.name === this.state.residenceCountries) || null}
                       inputValue = {this.state.residenceCountryAutcomplete}
                        onInputChange={this.changeResidenceInput}
                        PopperProps={{
                            modifiers: [
                              {
                                name: 'flip',
                                enabled: false, 
                              },
                              {
                                name: 'preventOverflow',
                                options: {
                                  altAxis: true, 
                                  tether: false, 
                                },
                              },
                            ],
                          }}
                          disablePortal={true} 
                    />

                    <Box>
                        {
                            (this.state.errors.residenceCountries)
                                ? <small style={webStyle.errorStyle}>{this.state.errors.residenceCountries}</small>
                                : <small style={{ visibility: 'hidden' }}>""</small>
                        }
                    </Box>
                </Grid>
                <Grid item xs={12} md={7} style={{ marginTop: '1.5em' }}>
                    <InputLabel style={webStyle.inputLabelStyle}>City/Province of Residence *</InputLabel>
                    <Autocomplete
                        testidentity="City_of_residense"
                        onChange={(e: any, newValue: any) => { this.onResidenceCitySelectedOne(newValue) }}
                        options={this.state.citiesListOne}
                        getOptionLabel={this.handleOptionLabelSelected}

                        getOptionSelected={this.handleOptionLabelSelectedTwo}
                        renderInput={(params: any) =>
                            <StyledTextField {...params} placeholder='Select City' fullWidth={true}
                                testidentity="PleaseSelect"
                                name='city' InputLabelProps={{ shrink: false }}
                            />}
                        value={{
                            "label": this.state.residenceCities,
                            "value": this.state.residenceCities
                        }}
                        
                        inputValue={this.state.residenceCityAutocomplete}
                        onInputChange={this.handleResidenseCitynewInputValue}
                    />
                    <Box>
                        {
                            (this.state.errors.residenceCities)
                                ? <small style={webStyle.errorStyle}>{this.state.errors.residenceCities}</small>
                                : <small style={{ visibility: 'hidden' }}>""</small>
                        }
                    </Box>
                </Grid>
            </>
        )
    }
    LessthantwentyFuncComplex = () => {
        return (
            <>
                <Grid item xs={12} md={7} >
                    <InputLabel style={webStyle.inputLabelStyle}>First Name *</InputLabel>
                    <StyledTextField
                        testidentity="Enter-first-name"
                        placeholder="Enter First Name"
                        fullWidth={true}
                        name='AccountFirstName'
                        value={this.state.AccountFirstName}
                        onChange={(e: any) => this.setState({
                            AccountFirstName: e.target.value
                        },
                        )}
                        onFocus={this.hideError}
                    />
                    <Box>
                        {
                            (this.state.errors.AccountFirstName)
                                ? <small style={webStyle.errorStyle}>{this.state.errors.AccountFirstName}</small>
                                : <small style={{ visibility: 'hidden' }}>""</small>
                        }
                    </Box>
                </Grid>
                <Grid item xs={12} md={7} style={{ marginTop: '1.5em' }} >
                    <InputLabel style={webStyle.inputLabelStyle}>Last Name *</InputLabel>
                    <StyledTextField
                        testidentity="Enter-last-name"
                        placeholder="Enter Last Name"
                        fullWidth={true}
                        name='AccountLastName'
                        value={this.state.AccountLastName}
                        onChange={(e: any) => this.setState({ AccountLastName: e.target.value })}
                        onFocus={this.hideError}
                    />
                    <Box>
                        {
                            (this.state.errors.AccountLastName)
                                ? <small style={webStyle.errorStyle}>{this.state.errors.AccountLastName}</small>
                                : <small style={{ visibility: 'hidden' }}>""</small>
                        }
                    </Box>
                </Grid>
                <Grid item xs={12} md={7} style={{ marginTop: '1.5em' }}>
                    <Box >
                        <InputLabel style={webStyle.inputLabelStyle}>Contact Number *</InputLabel>
                        <Box style={{ display: 'flex', alignItems: 'end' }}>
                            <Autocomplete
                                testidentity="contactNumber"
                                onChange={(e: React.ChangeEvent<HTMLInputElement>, newValue: IDItem) => {
                                    this.onCountryCodeSelectedWeb(newValue);
                                }}
                                style={{width: '190px'}}
                                options={this.state.countryPhoneCodeListOne}
                                disableClearable
                                getOptionLabel={(option) => `+ ${option.value}`}
                                getOptionSelected={(option, value) => option.value === value.value}
                                renderOption={(option) => (`+ ${option.value} - ${option.sortName}`)}
                                renderInput={(params) =>
                                    <StyledTextField {...params} placeholder='Select Code' fullWidth={true}
                                        testidentity="PleaseSelect"
                                        name='country' InputLabelProps={{ shrink: false }}
                                    />}
                                value={this.state.countryPhoneCodeListOne.find(item => item.value == this.state.AccountCountryCode) || null}
                    />
                            <StyledTextField
                                testidentity="input-phone-number"
                                inputProps={{ maxlength: 11 }}
                                style={{ marginLeft: '20px' }}
                                placeholder="1234567890"
                                fullWidth={true}
                                name='phone'
                                value={this.state.AccountMobileNumber}
                                onChange={(e: any) => this.onChangePhoneNumberOne(e.target.value)}

                                onFocus={this.hideError}
                            />
                        </Box>
                        <Box style={{ display: 'flex' }}>
                            <Box>
                                {
                                    (this.state.errors.AccountCountryCode)
                                        ? <small style={{...webStyle.errorStyle, width: '160px'}}>{this.state.errors.AccountCountryCode}</small>
                                        : <small style={webStyle.errorStyle}>{this.state.errors.AccountMobileNumber}</small>
                                }
                            </Box>
                        </Box>
                    </Box>
                </Grid>
                {this.againComplexcityresolve()}
            </>
        )
    }
    reaSonComplexcityFunc = () => {
        return (
            <>
                <Grid item xs={12} md={7} style={{ marginTop: '2em' }}>
                    <InputLabel style={webStyle.inputLabelStyle}>How did you learn about us? *</InputLabel>
                    <StyledTextField
                        testidentity="How did you learn about us"
                        select
                        color="darkgrey"
                        fullWidth
                        name="AccountAboutUs"
                        InputLabelProps={{ shrink: false }}
                        value={this.state.AccountAboutUs}
                        onFocus={this.hideError}
                        onChange={(e: any) => this.onLearnAboutUsSelectedOne(e.target)}
                    >

                        <MenuItem style={{ color: '#ABABB0' }} disabled value="choose"> <span style={{ color: "#ABABB0" }}>Please Select</span></MenuItem>
                        <MenuItem value="Homeschool Global Website">Homeschool Global Website</MenuItem>
                        <MenuItem value="Google/Internet">Google/Internet</MenuItem>
                        <MenuItem value="Family/Friends">Family/Friends</MenuItem>
                        <MenuItem value="Facebook">Facebook</MenuItem>
                        <MenuItem value="Church">Church</MenuItem>
                        <MenuItem value="Others">Others</MenuItem>


                    </StyledTextField>
                    <Box>
                        {
                            (this.state.errors.AccountAboutUs)
                                ? <small style={webStyle.errorStyle}>{this.state.errors.AccountAboutUs}</small>
                                : <small style={{ visibility: 'hidden' }}>""</small>
                        }
                    </Box>
                </Grid>

                <Grid item xs={12} md={7} style={{
                    marginTop: '1.5em', padding: '0'
                }}>
                    <Button

                        onClick={this.changeOpen} style={{ padding: '0' }}><span style={{
                            color: '#9167ac', fontFamily: "Montserrat", fontSize: '14px',
                            fontWeight: 400, textTransform: 'none'
                        }}
                            testId="changepassword"
                        >Change Password </span></Button>

                </Grid>
            </>
        )
    }

    passwordComplexCityFunc = () => {
        return (
            <>
                <Box>
                    {
                        (this.state.errors.newPassword)
                            ? <small style={webStyle.errorStyle}>{this.state.errors.newPassword}</small>
                            : <small style={{ visibility: 'hidden' }}>""</small>
                    }
                </Box>
                <Box style={{ marginTop: '1em' }}>
                    <InputLabel style={webStyle.inputLabelStyle}> Confirm Password *</InputLabel>
                    <StyledTextField

                        testidentity="Confirm_Password"
                        placeholder="Confirm Password"
                        fullWidth={true}
                        name="confirmNewPassword"
                        type={this.state.passwordConfirmState ? 'text' : 'password'} value={this.state.confirmNewPassword}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => this.setState({ confirmNewPassword: e.target.value })}
                        onFocus={this.hideError}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton onClick={this.changePasswordConfirmState}>
                                        {
                                            this.state.passwordConfirmState ?
                                                <Visibility style={webStyle.EmailOutlinedIcon} />
                                                : <VisibilityOff style={webStyle.EmailOutlinedIcon} />
                                        }

                                    </IconButton>
                                </InputAdornment>
                            ),
                        }}
                    />
                </Box>
                <Box>
                    {
                        (this.state.errors.confirmNewPassword)
                            ? <small style={webStyle.errorStyle}>{this.state.errors.confirmNewPassword}</small>
                            : <small style={{ visibility: 'hidden' }}>""</small>
                    }
                </Box>
            </>

        )
    }
    // Customizable Area End

    render() {
        return (
            // Customizable Area Start

            <>
                <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                    <AppWithoutDrawer navigation={this.props.navigation} />
                    <Sidebarmenu activeKey="Account" />
                    <ThemeProvider theme={theme}>
                        <Container maxWidth={false} >
                                <Grid container style={{ display: 'block' }}>

                                    <Grid item>
                                        <Container maxWidth={false} >
                                                <Container maxWidth={false} style={{ margin: '0 auto' }}>
                                                    <Box style={{ marginTop: '80px', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                                                        <Typography style={webStyle.welcomeTextStyle} >Account Settings </Typography>
                                                    </Box>
                                                    <Grid container style={{ display: 'flex' }}>
                                                        <HorizontalLine />
                                                    </Grid>

                                                    <Container maxWidth={false} style={webStyle.GridWrapperparent}>
                                                        {
                                                            this.state.gettingDetailsOfStudent ?
                                                                <Box style={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}> <CircularProgress /></Box>
                                                                : <>
                                                                    <Grid container style={{ paddingLeft: '1em', marginTop: '2em', }}   >
                                                                        {this.LessthantwentyFuncComplex()}
                                                                        {this.conditionalRenderFunc()}
                                                                        {this.reaSonComplexcityFunc()}
                                                                        <Modal
                                                                            open={this.state.open}
                                                                            onClose={this.handleModelClose}
                                                                        >
                                                                            <Box style={webStyle.modalStyle}>
                                                                                <Box style={{ marginTop: '20px', display: 'flex', flexDirection: 'column' }}>
                                                                                    <Typography style={webStyle.modalTextTitle}>
                                                                                        Change Password</Typography>
                                                                                </Box>
                                                                                <form onSubmit={(e)=>this.updatePasscode(e)}>
                                                                                <Box style={{ paddingLeft: "3em", paddingRight: '3em' }}>
                                                                                    <Box style={{ marginTop: '20px' }}>
                                                                                        <InputLabel style={webStyle.inputLabelStyle}> Current Password *</InputLabel>
                                                                                        <StyledTextField
                                                                                            testidentity="Current_Password"
                                                                                            placeholder="Current Password"
                                                                                            fullWidth={true}
                                                                                            type={this.state.passwordState ? 'text' : 'password'}
                                                                                            name="currentPassword"
                                                                                            value={this.state.currentPassword}
                                                                                            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                                                                                this.setState(
                                                                                                    { currentPassword: e.target.value })}
                                                                                            onFocus={this.hideError}
                                                                                            InputProps={{
                                                                                                endAdornment: (
                                                                                                    <InputAdornment position="end"  >
                                                                                                        <IconButton onClick={this.changePasswordState}
                                                                                                            testidentity="changeNewPassword"
                                                                                                        >
                                                                                                            {
                                                                                                                this.state.passwordState ?
                                                                                                                    <Visibility style={webStyle.EmailOutlinedIcon} />
                                                                                                                    : <VisibilityOff style={webStyle.EmailOutlinedIcon} />
                                                                                                            }

                                                                                                        </IconButton>
                                                                                                    </InputAdornment>
                                                                                                ),
                                                                                            }}
                                                                                        />
                                                                                    </Box>

                                                                                    <Box>
                                                                                        {
                                                                                            (this.state.errors.currentPassword)
                                                                                                ? <small style={webStyle.errorStyle}>{this.state.errors.currentPassword}</small>
                                                                                                : <small style={{ visibility: 'hidden' }}>""</small>
                                                                                        }
                                                                                    </Box>
                                                                                    <Box style={{ marginTop: '1em' }}>
                                                                                        <InputLabel style={webStyle.inputLabelStyle}> New Password *</InputLabel>
                                                                                        <StyledTextField
                                                                                            testidentity="New_Password"
                                                                                            placeholder="New Password"
                                                                                            fullWidth={true}
                                                                                            name="newPassword" type={this.state.passwordCreateState ? 'text' : 'password'}
                                                                                            value={this.state.newPassword}
                                                                                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => this.setState({ newPassword: e.target.value })}
                                                                                            onFocus={this.hideError}
                                                                                            InputProps={{
                                                                                                endAdornment: (
                                                                                                    <InputAdornment position="end"  >
                                                                                                        <IconButton
                                                                                                            onClick={this.changePasswordCreateState}
                                                                                                            testidentity="changepasswordCreateState"

                                                                                                        >
                                                                                                            {
                                                                                                                this.state.passwordCreateState ?
                                                                                                                    <Visibility style={webStyle.EmailOutlinedIcon} />
                                                                                                                    : <VisibilityOff style={webStyle.EmailOutlinedIcon} />
                                                                                                            }

                                                                                                        </IconButton>
                                                                                                    </InputAdornment>
                                                                                                ),
                                                                                            }}
                                                                                        />
                                                                                    </Box>
                                                                                    {this.passwordComplexCityFunc()}
                                                                                    <Box style={{ textAlign: 'center', marginBottom: '20px', marginTop: '1em' }}>
                                                                                        <Button
                                                                                            type="submit"
                                                                                            style={{ textTransform: 'none', ...webStyle.UpdatePassword }}
                                                                                            onClick={(e)=>this.updatePasscode(e)}
                                                                                            testidentity="updatepassword"
                                                                                        >Update Password </Button>
                                                                                    </Box>
                                                                                </Box>
                                                                                </form>
                                                                                <Snackbar anchorOrigin={{
                                                                                    vertical: 'top',
                                                                                    horizontal: 'center',
                                                                                }} open={this.state.snackbarOpen} autoHideDuration={4000} onClose={this.handleClose} >
                                                                                    <Alert severity="success">
                                                                                        {this.state.snackbarMessage}
                                                                                    </Alert>
                                                                                </Snackbar>
                                                                                <Snackbar anchorOrigin={{
                                                                                    vertical: 'top',
                                                                                    horizontal: 'center',
                                                                                }} open={this.state.snackbarOpenError} autoHideDuration={4000} onClose={this.handleClose1} >
                                                                                    <Alert severity="error">
                                                                                        {this.state.snackbarMessage}
                                                                                    </Alert>
                                                                                </Snackbar>
                                                                            </Box>
                                                                        </Modal>
                                                                    </Grid>
                                                                    <div style={{ textAlign: 'right', marginBottom: "3em", }}>
                                                                        <Button
                                                                            type='submit'
                                                                            disableElevation
                                                                            disableRipple
                                                                            style={{ textTransform: 'none', ...webStyle.UpdateSettings }}
                                                                            onClick={this.onSubmitAccountSettingOne}
                                                                            testidentity="sunbmtAllData"
                                                                        >Update Settings
                                                                        </Button>
                                                                        <Modal open={this.state.updateOpen}
                                                                        >
                                                                            <Box style={webStyle.modalStyleAccount}>
                                                                                <Box style={{ marginTop: '20px', marginBottom: '20px', display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column' }}>
                                                                                    <Box>
                                                                                        <CheckCircleIcon style={webStyle.CheckBoxStyle} />
                                                                                    </Box>
                                                                                    <Typography align='center' style={webStyle.Successfully}>Account settings
                                                                                        <br></br>
                                                                                        updated successfully.</Typography>
                                                                                </Box>

                                                                                <button style={webStyle.cancelButton as React.CSSProperties} autoFocus onClick={this.handleCloseAccount}>
                                                                                    <CloseOutlinedIcon fontSize="small" />
                                                                                </button>
                                                                            </Box>
                                                                        </Modal>

                                                                        <Modal open={this.state.logoutModal}
                                                                        >
                                                                            <Box style={webStyle.modalStyleSuccess}>
                                                                                <Box style={{ marginTop: '20px', marginBottom: '20px', display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column' }}>
                                                                                    <Box style={{ paddingBottom: '1em' }}>
                                                                                        <CheckCircleIcon style={webStyle.CheckBoxStyle} />
                                                                                    </Box>
                                                                                    <Typography align='center' style={webStyle.Successfully}>Successfully changed account Password.
                                                                                        <br></br>
                                                                                        Please login again.</Typography>
                                                                                </Box>
                                                                                <Box style={{ textAlign: 'center', marginBottom: '20px' }}>
                                                                                        <Button
                                                                                            style={{ textTransform: 'none', ...webStyle.UpdatePassword}}
                                                                                            onClick={this.loginAgain}
                                                                                            testidentity="login"
                                                                                        >Ok</Button>
                                                                                    </Box>
                                                                            </Box>
                                                                        </Modal>
                                                                    </div>
                                                                </>

                                                        }


                                                    </Container>
                                                </Container>
                                            </Container>
                                    </Grid>
                                </Grid>
                        </Container>
                    </ThemeProvider >
                </div>
            </>
            // Customizable Area End
        );
    }
}

// Customizable Area Start
const webStyle = {
    UpdatePassword: {
        height: "52px",
        border: "none",
        borderRadius: "10px",
        backgroundColor: " #fab03d",
        color: '#fafafb',
        fontFamily: "Montserrat",
        fontSize: ' 16px',
        fontWeight: 600,
        padding: '1.5em',
    },
    Successfully: {
        color: '#171725',
        fontFamily: "Montserrat",
        fontSize: '15px',
        fontWeight: 600,
        width: '333px',

    },
    CheckBoxStyle: {
        width: '42px',
        height: '42px',
        color: ' #99cb51'
    },
    UpdateSettings: {
        height: "52px",
        marginTop: "40px",
        border: "none",
        borderRadius: "10px",
        backgroundColor: " #fab03d",
        color: '#fafafb',
        fontFamily: "Montserrat",
        fontSize: ' 16px',
        fontWeight: 600,
        padding: '1.5em'
    },
    modalStyle: {
        position: 'relative' as 'relative',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '520px',
        borderRadius: '20px',
        paddingTop: '2px',
        paddingLeft: '4px',
        paddingRight: '4px',
        paddingBottom: '3px',
        backgroundColor: '#ffffff',
    },
    modalTextTitle: {
        color: '#171725',
        fontFamily: "Montserrat",
        fontSize: '16px',
        fontWeight: 600,
        lineHeight: '24px',
        paddingLeft: '2.6em'

    },
    errorStyle: {
        color: 'red',
        fontFamily: "Montserrat",
        fontSize: ' 12px',
    },
    inputLabelStyle: {
        marginBottom: '10px',
        height: '22px',
        color: "#757575",
        fontFamily: "Montserrat",
        fontSize: '12px',
        fontWeight: 400,
        lineHeight: '22px',
    },
    welcomeTextStyle: {
        //marginTop: '50px',
        color: '#171725',
        fontFamily: "Montserrat",
        fontSize: '24px',
        fontWeight: 600,
    },
    GridWrapperparent: {
        borderRadius: "15px",
        background: "#ffffff",
        border: "1px solid #f1f1f5",
        marginTop: "2em",
        boxShadow: "rgba(68, 68, 79, 0.1) 0px 5px 15px"

    },
    EmailOutlinedIcon: {
        color: "#b5b5be"
    },
    modalStyleAccount: {
        position: 'relative' as 'relative',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '300px',
        borderRadius: '20px',
        paddingTop: '2px',
        paddingLeft: '4px',
        paddingRight: '4px',
        paddingBottom: '3px',
        backgroundColor: '#ffffff',
    },
    boxCancelIcon: {
        paddingTop: '2px',
        borderRadius: '50px',
        color: '#fff',
        backgroundColor: '#B98ED6',
        width: '32px',
        height: '32px'
    },
    closeIconModal: {
        position: 'absolute' as 'absolute',
        right: '-15px',
        top: '-15px',
    },
    cancelButton: {
        position: "absolute",
        top: "-13px",
        background: "rgb(185, 142, 214)",
        color: "white",
        right: "-13px",
        borderRadius: '50%',
        border: 'none',
        cursor: 'pointer',  
        padding: '5px'
      },
      modalStyleSuccess: {
        position: 'relative' as 'relative',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '400px',
        borderRadius: '20px',
        paddingTop: '2px',
        paddingLeft: '4px',
        paddingRight: '4px',
        paddingBottom: '3px',
        backgroundColor: '#ffffff',
    },
};

export default AccountSettings
// Customizable Area End
