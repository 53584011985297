import React, { useEffect } from 'react';

const HappyFoxChat = () => {
  useEffect(() => {
    const scriptContent = `
      (function() {
        // Get query parameters
        const queryParams = new URLSearchParams(window.location.search);
        const username = queryParams.get('username');
        const email = queryParams.get('email');
        const phoneNo = queryParams.get('phoneno');
        const islogout = queryParams.get('islogout');

    try {
      window.HFCHAT_CONFIG = {
        EMBED_TOKEN: 'a30cfdc0-8791-11ef-b38f-293e48c91ded',
        ASSETS_URL: 'https://widget.happyfoxchat.com/v2/visitor',
        onload: function () {
          var HappyFoxChat = this;
          if (islogout) {
            HappyFoxChat.unsetVisitor(function (err) {
              if (err) {
                console.error('Failed to reset the visitor. Error:', err);
              } else {
                console.log('Visitor reset successful');
              }
            });
          }
          HappyFoxChat.setVisitorInfo({
            name: username,
            email: email,
            phoneNumber: phoneNo,
          }, function (err, resp) {
            if (err) {
              console.error('Failed to set visitor details. Error:', err);
            } else {
              console.log('Added visitor details:', resp);
            }
          });
          HappyFoxChat.expandChatbox();
        },
        options: {
          forceShowWidget: true,
          hideLauncher: false,
          hideChatbox: false,
          showBadgeOnLoad: true
        }
      };

      var scriptTag = document.createElement('script');
      scriptTag.type = 'text/javascript';
      scriptTag.async = true;
      scriptTag.src = window.HFCHAT_CONFIG.ASSETS_URL + '/js/widget-loader.js';

      var s = document.getElementsByTagName('script')[0];
      if (s && s.parentNode) {
        s.parentNode.insertBefore(scriptTag, s);
      } else {
        document.head.appendChild(scriptTag);
      }
    } catch (error) {
      console.error('Error loading chat widget:', error);
    }

    function triggerButtonClick() {
      var button = document.getElementById('myButton');
      if (button) {
        button.click(); 
        console.log('clicked');
      }
    }

    setTimeout(triggerButtonClick, 5000); 
  })();
`;

const scriptElement = document.createElement('script');
scriptElement.type = 'text/javascript';
scriptElement.text = scriptContent;
document.body.appendChild(scriptElement);

return () => {
  document.body.removeChild(scriptElement);
};
  }, []);

  return (
    <div>
      <button id="myButton" style={{ display: 'none' }}>Click Me!</button>
    </div>
  );
};

export default HappyFoxChat;